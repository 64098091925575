import React, { FC } from 'react';
import { SxProps } from '@mui/system';

import styled from '@mui/system/styled';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';

const StyledButton = styled(ButtonUnstyled)({});
const StyledSpan = styled('span')(() => {
  return {
    fontSize: '24px',
    marginLeft: '5px',
  };
});

interface Props {
  onClick?: (event) => void;
  sx?: SxProps;
  playIcon?: React.ReactNode;
}

const PlayButton: FC<Props> = ({ onClick, sx, playIcon }) => {
  return (
    <StyledButton
      aria-label='Play'
      onClick={onClick}
      sx={{
        bgcolor: 'white',
        color: 'black',
        border: '2px solid black',
        borderRadius: '50% !important',
        width: '88px',
        height: '88px',
        cursor: 'pointer',
        '&:hover, :focus': {
          color: 'black',
          backgroundColor: '#DBFF00',
        },
        '&:active': {
          color: 'black',
          backgroundColor: '#D5E41A',
        },
        transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
        ...sx,
      }}
    >
      {playIcon ? playIcon : <StyledSpan>&#9658;</StyledSpan>}
    </StyledButton>
  );
};

export default PlayButton;
