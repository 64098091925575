import React from 'react';
import MUIGrid from '@mui/system/Unstable_Grid';

import { GridProps } from './Grid.types';

const Row = React.forwardRef(
  ({ children, ...props }: GridProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <MUIGrid
        ref={ref}
        container
        columns={24}
        spacing={{ xs: '0.4rem', sm: '0.8rem', md: '1.6rem' }}
        {...props}
      >
        {children}
      </MUIGrid>
    );
  },
);

export default Row;
