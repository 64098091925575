import * as React from 'react';
import { useSpring, animated, easings } from '@react-spring/web';
import { IBtnProps } from './Btn.types';
import { Icon } from 'src/components-v2/DataDisplay';
import { Stack as VStack, Box as VBox } from 'src/components-v2/Layout';
import StyledButton from './Btn.styled';
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';
import { useIsDarkBackground } from 'src/contexts';
import { ConditionalWrapper } from 'src/components-v2/Helpers';
import Link from '../Link/Link';
import { colors } from 'src/components-v2/theme';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const Box = animated(VBox);
const Stack = animated(VStack);

const Btn = ({
  children,
  isInverted = false,
  trackingId = '',
  variant = 'primary',
  sys,
  backgroundColor,
  ...props
}: IBtnProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const track = useTrack();
  const isDarkBackground = useIsDarkBackground(backgroundColor);
  const [isHovered, setIsHovered] = React.useState(false);

  const isPrimary = variant === 'primary';
  const isTertiary = variant === 'tertiary';
  const isQuinary = variant === 'quinary';
  const isOnDarkBackground =
    isDarkBackground !== undefined ? isDarkBackground : isInverted;

  const animatedText = useSpring({
    from: {
      left: '0px',
    },
    to: {
      left: isHovered && isTertiary ? '-8px' : '0px',
    },
    config: {
      duration: 300,
      easing: easings.easeOutBack,
    },
  });
  const animatedIcon = useSpring({
    from: {
      left: '0%',
      backgroundColor: colors.black400,
    },
    to: {
      left: isHovered ? '100%' : '0%',
      backgroundColor: isHovered ? colors.transparent : colors.black400,
    },
    config: {
      duration: 300,
      easing: easings.easeOutBack,
    },
  });

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
    if (props.onClick && !props.href) {
      props.onClick(event);
    }
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = (e) => {
    setIsHovered(false);
    e.target.blur();
  };

  return (
    <ConditionalWrapper
      condition={props.href}
      wrapper={(children) => {
        // we render the button as a hyperlink when href prop is present
        // we also need to wrap the anchor in our specialized Link component
        // The Link component wrapper will handle localization and tracking
        return <Link href={props.href}>{children}</Link>;
      }}
    >
      <StyledButton
        {...props}
        isInverted={isOnDarkBackground}
        onBlur={handleMouseOut}
        onClick={handleClick}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        variant={variant}
        {...inspectorProps({ fieldId: 'text' })}
      >
        <Stack
          alignItems='center'
          component={'span'}
          flexDirection='row'
          gap={isTertiary ? 1 : isQuinary ? 0.5 : 1.5}
          justifyContent={isQuinary ? 'left' : 'center'}
        >
          {isTertiary && (
            <Stack
              alignItems={'center'}
              className='movingIcon'
              component={'span'}
              justifyContent={'center'}
              style={animatedIcon}
              sx={{
                borderRadius: '50%',
                height: '20px',
                position: 'relative',
                width: '20px',
                willChange: isHovered ? 'left, background-color' : 'auto',
                zIndex: 0,
              }}
            >
              <Icon
                sx={{
                  height: '14px',
                  width: '14px',
                }}
                icon='ArrowRight'
              />
            </Stack>
          )}
          <Box
            className='movingText'
            component='span'
            sx={{
              backgroundColor: 'inherit',
              position: 'relative',
              willChange: isHovered ? 'left' : 'auto',
              zIndex: 1,
            }}
            style={animatedText}
          >
            {children}
          </Box>
          {isPrimary && (
            <Icon
              sx={{
                color: isOnDarkBackground ? 'black' : 'white',
                height: '20px',
                width: '20px',
              }}
              icon='ArrowRight'
            />
          )}
          {isQuinary && (
            <Icon
              sx={{
                color: isOnDarkBackground ? 'black' : 'white',
                minHeight: '24px',
                minWidth: '24px',
                maxHeight: '24px',
                maxWidth: '24px',
                marginRight: '16px',
              }}
              icon='ArrowRight'
            />
          )}
        </Stack>
      </StyledButton>
    </ConditionalWrapper>
  );
};

export default Btn;
