import React from 'react';

import { ContainerProps } from './Container.types';
import styled from '@mui/system/styled';
// set defaults for container that can be overridden with sx props

const StyledDiv = styled('div')({});
const Container: React.FC<ContainerProps> = ({
  children,
  className = '',
  sx,
  ...props
}) => {
  return (
    <StyledDiv
      className={className}
      {...props}
      sx={{
        margin: '0 auto',
        maxWidth: '1730px',
        px: {
          xs: '1.3rem',
          sm: '4rem',
        },
        py: 0,
        ...sx,
      }}
    >
      {children}
    </StyledDiv>
  );
};

export default Container;
