import React, { FC, useState, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import ThreadsTextInput from 'plaid-threads/TextInput';
import cx from 'classnames';
import { nanoid } from 'nanoid';

import styles from './TextInput.module.scss';
import { Option } from '../Select/Select';

export interface InputProps {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  describedby?: string;
  disabled?: boolean;
  errors?: Record<string, unknown>;
  errorMessage?: string;
  id: string;
  isDarkMode?: boolean;
  label: string;
  iconButtons?: React.ReactElement[];
  name: string;
  pattern?: Record<string, unknown>;
  placeholder?: string;
  minRows?: number;
  /* register accepts varying arguments & the ReturnType method fails */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  register?: any;
  required?: string;
  type?: string;
  value?: string;
  defaultValue?: string | Option;
  onChange?: (arg) => void;
  autoComplete?: string;
}

const TextInput: FC<InputProps> = ({
  className = styles.textInput,
  isDarkMode,
  pattern,
  register = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  required,
  defaultValue = '',
  value = defaultValue || '',
  onChange = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnChange = (e) => {
    onChange(e);
    setInputValue(e.target.value);
  };

  return (
    <ThreadsTextInput
      {...restProps}
      {...(restProps.id ? { id: `${restProps.id}-${nanoid()}` } : {})}
      className={cx(className, isDarkMode && styles.textInputDark)}
      isDarkMode={isDarkMode}
      onChange={(arg) => {
        handleOnChange(arg);
      }}
      ref={register({
        ...(required && { required }),
        ...(pattern && { pattern }),
      })}
      value={inputValue as string}
    />
  );
};

TextInput.displayName = 'TextInput';
export default TextInput;
