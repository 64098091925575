import React from 'react';
import MUIBox, { BoxProps } from '@mui/system/Box';

const Box = React.forwardRef(({ children, ...props }: BoxProps, ref) => {
  return (
    <MUIBox ref={ref} {...props}>
      {children}
    </MUIBox>
  );
});

export default Box;
