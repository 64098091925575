import React, { FC } from 'react';
import Image from 'src/components/Image';
import { ImageShape } from 'src/lib/ts-types';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

interface FeaturedStoryProps {
  'bg-classes': string;
  headline: string;
  id: string;
  image: ImageShape;
  subhead: string;
  url: string;
}

/**
 * @TODO https://jira.plaid.com/browse/SITE-2488
 * bg-classes / background impl considered deprecated,
 * tech debt to be removed once Customer Stories are 100% under CMS control
 */
const FeaturedStory: FC<FeaturedStoryProps> = ({
  'bg-classes': bgClasses,
  headline,
  id,
  image,
  subhead,
  url,
}) => {
  return (
    <div className='customer-stories__feature'>
      <article>
        <Link href={url}>
          <a className='customer-story-feature' data-filterable-feature-id={id}>
            <div className='grid-x'>
              <div className='cell small-12 medium-6 large-7'>
                <div
                  className={'customer-story-feature__image-block ' + bgClasses}
                >
                  <Image
                    src={image.src}
                    alt={image.alt}
                    className='customer-story-feature__image'
                    isLazy={false}
                  />
                </div>
              </div>
              <div className='cell small-12 medium-6 large-5'>
                <div className='customer-story-feature__text-block'>
                  <h4 className='customer-story-feature__headline'>
                    {headline}
                  </h4>
                  <p className='customer-story-feature__subhead'>{subhead}</p>
                  <p className='customer-story-feature__cta'>
                    <span className='feature-section-link-text'>
                      Read full story <ArrowRightSingleSmall />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </article>
    </div>
  );
};

export default FeaturedStory;
