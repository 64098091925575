/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import cx from 'classnames';
import { RenderPropSticky } from 'react-sticky-el';
import Slider from 'react-slick';
import Collapsible from 'react-collapsible';

// components
import { ConditionalWrapper } from 'src/components/helpers';

// helpers
import { useDeviceSize } from 'src/hooks';

// contexts
import { useTrack } from 'src/contexts/AnalyticsContext';
import { CUSTOMER_STORIES_EVENTS } from 'src/lib/trackingEvents';

const settings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  dots: false,
  centerMode: false,
  centerPadding: '50px',
  variableWidth: true,
  infinite: false,
};

interface Filter {
  label: string;
  active: boolean;
  disabled: boolean;
}

interface Props {
  filters: Array<Filter>;
  handleResetFilter: () => void;
  handleClick: ({ active, label }) => void;
}

const Filters: React.FC<Props> = ({
  filters = [],
  handleResetFilter,
  handleClick,
}) => {
  const track = useTrack();
  const device = useDeviceSize();
  const [state, setState] = useState({
    toggled: false,
  });

  const toggleAccordion = () => {
    setState({ toggled: !state.toggled });
  };

  return (
    <div>
      <p className='filters-sticky-container__label show-for-large'>
        <strong>I want to...</strong>
      </p>
      <RenderPropSticky boundaryElement='.customer-stories__scroll-boundary'>
        {({ isFixed, wrapperStyles, wrapperRef, holderStyles, holderRef }) => {
          return (
            <div ref={holderRef} style={holderStyles}>
              <div
                className={cx('customer-stories__sticky-filters', {
                  'customer-stories__sticky-filters--fixed': isFixed,
                })}
                style={wrapperStyles}
                ref={wrapperRef}
              >
                <ConditionalWrapper
                  condition={!device.isLarge}
                  wrapper={(children) => (
                    <Collapsible
                      transitionTime={200}
                      trigger={state.toggled ? 'Hide filters' : 'Show filters'}
                      onOpening={toggleAccordion}
                      onClosing={toggleAccordion}
                    >
                      {children}
                    </Collapsible>
                  )}
                >
                  <ul className='filters-ui' data-id='filters-ui'>
                    <ConditionalWrapper
                      condition={isFixed && device.isLarge}
                      wrapper={(children) => (
                        <Slider {...settings}>{children}</Slider>
                      )}
                    >
                      <li className='filters-ui__item'>
                        <button
                          type='button'
                          onClick={handleResetFilter}
                          className='button filters-ui__btn'
                        >
                          See all goals
                        </button>
                      </li>
                      {filters.map(({ active, label, disabled }) => {
                        return (
                          <li
                            className={cx('filters-ui__item', {
                              'filters-ui__btn--is-active': active,
                            })}
                            key={`filter-${label}`}
                          >
                            <button
                              onClick={() => {
                                track({
                                  type:
                                    CUSTOMER_STORIES_EVENTS.USER_TOGGLED_CUSTOMER_STORIES_FILTER,
                                  payload: {
                                    active: !active,
                                    label,
                                  },
                                });
                                handleClick({
                                  active,
                                  label,
                                });
                              }}
                              type='button'
                              className={cx('button filters-ui__btn', {
                                'filters-ui__btn--is-active': active,
                              })}
                              disabled={disabled}
                              data-filter-id={label}
                            >
                              {label}
                            </button>
                          </li>
                        );
                      })}
                    </ConditionalWrapper>
                  </ul>
                </ConditionalWrapper>
              </div>
            </div>
          );
        }}
      </RenderPropSticky>
    </div>
  );
};

export default Filters;
