import * as React from 'react';
import { SxProps } from '@mui/system';
import useMeasure from 'react-use-measure';
import { useSwipeable } from 'react-swipeable';
import { useDebouncedCallback } from 'use-debounce';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Sys } from 'contentful';
import _clamp from 'lodash/clamp';
import {
  Box,
  Column,
  Container,
  Row,
  Section,
  Stack,
  Spacer,
} from 'src/components-v2/Layout';
import { ImageCards } from './ImageCards';
import { StatsCards } from './StatsCards';

import { getStatCardStyles, imageCardStyles } from './cardStyles';
import { useDeviceSize } from 'src/hooks';

export interface IPaginatedCard {
  content: Array<React.ReactElement<unknown>>;
  sx: SxProps;
}

interface IPaginatorProps {
  cards: Array<IPaginatedCard> | Array<React.ReactElement<unknown>>;
  sx?: SxProps;
  sys?: Sys;
  heading?: React.ReactElement;
  appearance?: 'stat' | 'image';
}

const components = {
  stat: StatsCards,
  image: ImageCards,
};

const Paginator: React.FC<IPaginatorProps> = ({
  cards,
  sx,
  sys,
  heading,
  appearance = 'image',
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const PaginatedCards = components[appearance];
  const cardStyles = {
    image: imageCardStyles,
    stat: getStatCardStyles(cards),
  };
  const initialState =
    cards?.map((c, i) => {
      return i === 0 ? true : false;
    }) || [];
  const [isActive, setIsActive] = React.useState(initialState);
  const [xPos, translateX] = React.useState(0);
  const [cardRef, cardRefBounds] = useMeasure();
  const [rowRef, rowRefBounds] = useMeasure();
  const device = useDeviceSize();
  const activeCard = isActive.findIndex((c) => {
    return c === true;
  });

  const getX = (index) => {
    const marginLeft =
      appearance === 'image' ? (rowRefBounds.width * 1) / 24 : 0;
    return (cardRefBounds.width + marginLeft) * index;
  };

  const handleClick = (index) => {
    const x = getX(index);
    translateX(x);
    setIsActive((prevState) => {
      const newState = prevState.map((c, i) => {
        if (i === index) {
          return true;
        }
        return false;
      });
      return newState;
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleClick(_clamp(activeCard + 1, 0, cards.length - 1));
    },
    onSwipedRight: () => {
      handleClick(_clamp(activeCard - 1, 0, cards.length - 1));
    },
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    swipeDuration: 1000, // allowable duration of a swipe (ms)
    touchEventOptions: { passive: true }, // options for touch listeners
  });

  const recalculateX = useDebouncedCallback((index) => {
    const x = getX(index);
    translateX(x);
  }, 100);

  React.useEffect(() => {
    // reset the rows, this is uncommon, but will happen during testing and will scare people
    if (!device.isSmall && xPos !== 0) {
      translateX(0);
      setIsActive(initialState);
    }

    window.addEventListener('resize', recalculateX.bind(null, activeCard));
    return () => {
      window.removeEventListener('resize', recalculateX);
    };
  }, [recalculateX, device.isSmall, initialState, isActive, xPos, activeCard]);

  return (
    <Section sx={{ ...sx }} {...sx}>
      {heading && heading}
      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        {/* we have a blank row just for measuring since merge refs is not working */}
        <Row ref={rowRef} />
        <Row
          ref={handlers.ref}
          sx={{
            flexWrap: { xs: 'nowrap' },
            transform: `translateX(-${xPos}px)`,
            transition: 'transform 0.3s ease-in-out',
            ...cardStyles[appearance],
          }}
          {...handlers.onMouseDown}
        >
          <PaginatedCards
            cards={cards}
            cardRef={cardRef as React.Ref<HTMLDivElement>}
          />
        </Row>
        <Spacer sx={{ height: { xs: '15px', sm: '0' } }} />
        <Row sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <Column xs={24}>
            <Stack justifyContent='center' flexDirection='row' gap={0}>
              {cards?.map((card, i) => {
                return (
                  <Indicator
                    key={`indicator-${i}`}
                    isActive={isActive[i]}
                    index={i}
                    onClick={handleClick}
                  />
                );
              })}
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const Indicator = ({ isActive = false, index, onClick, sx = {} }) => {
  return (
    <Box
      onClick={() => {
        onClick(index);
      }}
      key={`indicator-${index}`}
      component='button'
      aria-label='indicator'
      tabIndex={0}
      role='button'
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') onClick(index);
      }}
    >
      <Box
        sx={{
          height: '14px',
          width: isActive ? '28px' : '14px',
          bgcolor: isActive ? 'black700' : 'black500',
          borderRadius: '8px',
          margin: isActive ? '2.0rem 0.8rem' : '2.0rem 0.8rem',
          ...sx,
        }}
      />
    </Box>
  );
};

export { Paginator, Indicator };
