import React from 'react';
import ChevronRightS2 from 'plaid-threads/Icons/ChevronRightS2';

import styled from '@mui/system/styled';

import styles from './Link.module.scss';

const StyledChevron = styled(ChevronRightS2)(() => {
  return {};
});
const CtaLink = ({ children }) => {
  return (
    <span className={styles.cta}>
      <span className={styles.chevronWrapper}>
        {children}
        <StyledChevron
          sx={{
            position: 'absolute',
            right: '-21px',
            top: { xs: '-.8px', sm: '-1px', md: '-.25px', lg: '-.15px' },
          }}
          className={styles.rightChevron}
        />
      </span>
    </span>
  );
};

export default styled(CtaLink)({});
