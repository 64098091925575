import React from 'react';
import { Section, Container, Row } from 'src/components-v2/Layout';
import { SxProps } from '@mui/system';
import { SectionProps } from 'src/components-v2/Layout/Section/Section.types';

interface IWrapper {
  children: React.ReactNode;
  component?: SectionProps['component'];
  sx?: SxProps | { backgroundColor: string };
  className?: string;
}

const Wrapper = React.forwardRef(
  ({ component = 'section', sx = {}, children, ...rest }: IWrapper, ref) => {
    return (
      <Section component={component} sx={sx} {...rest} ref={ref} {...sx}>
        <Container>
          <Row>{children}</Row>
        </Container>
      </Section>
    );
  },
);
Wrapper.displayName = 'Wrapper';

export default Wrapper;
