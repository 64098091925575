import React from 'react';
import Image from 'src/components/Image';

interface Props {
  image: { src: string; alt: string };
}

const QuoteWithImage: React.FC<Props> = ({ image, children }) => {
  return (
    <div className='grid-container'>
      <div className='grid-x align-middle'>
        <div className='cell small-12 medium-4'>
          <div className='grid-x'>
            <div className='cell small-8 medium-8 small-offset-2 medium-offset-0'>
              <Image src={image.src} alt={image.alt} aria-hidden='true' />
            </div>
          </div>
        </div>
        <div className='cell small-12 medium-8'>{children}</div>
      </div>
    </div>
  );
};

export default QuoteWithImage;
