import * as optimizely from '@optimizely/optimizely-sdk';

// This follows the link-web-client implementation: https://github.plaid.com/plaid/link-web-client/pull/4159

// Overrides the default Optimizely EventDispatcher so that requests to Optimizely are routed
// through analytics.plaid.com. This is done because some clients' Content Security Policies
// will block any requests that aren't going to *.plaid.com
const eventDispatcher: optimizely.EventDispatcher = {
  dispatchEvent(event: optimizely.Event, callback): void {
    const dataString = JSON.stringify(event.params);

    fetch('https://analytics.plaid.com/optimizely/v1/events', {
      method: 'POST',
      // we're ok with no-cors because we're only reading the status code.
      // if we end up needing a detailed response we'll need to establish CORS access
      // on the proxy server
      // mode: 'no-cors',
      headers: {
        'content-type': 'application/json',
        'content-length': dataString.length.toString(),
      },
      body: dataString,
    })
      .then((response) => {
        if (
          response &&
          response.status &&
          response.status >= 200 &&
          response.status < 400
        ) {
          callback({ statusCode: response.status });
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  },
};
export default eventDispatcher;
