import React from 'react';
import MUIStack, { StackProps } from '@mui/system/Stack';

const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  ({ children, ...props }, ref) => {
    return (
      <MUIStack {...props} ref={ref}>
        {children}
      </MUIStack>
    );
  },
);

export default Stack;
