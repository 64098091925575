import styled from '@mui/system/styled';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { PlaidTheme } from 'src/components-v2/theme';
import { variants } from './Btn.types';

type Props = {
  variant: variants;
  isInverted: boolean;
} & { theme?: PlaidTheme };

const StyledButton = styled(ButtonUnstyled)(
  ({ theme, variant, isInverted }: Props) => {
    // color inversion
    const isBlackOrWhite = isInverted
      ? theme.palette.black
      : theme.palette.white;
    const isWhiteOrBlack = isInverted
      ? theme.palette.white
      : theme.palette.black;

    // animation settings
    const transitionIn = '200ms';
    const transitionOut = '350ms';
    const transitionTimingFunction = 'cubic-bezier(0.23, 1.2, 0.32, 1)';

    const sharedStyles = {
      alignItems: 'center',
      background: isWhiteOrBlack,
      border: 'solid 1px',
      borderColor: isWhiteOrBlack,
      borderRadius: '240px',
      color: isBlackOrWhite,
      display: 'inline-flex',
      fontFamily: theme.typography.sans,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: '2.0rem',
      fontStyle: 'normal',
      fontWeight: 600,
      height: '48px',
      justifyContent: 'center',
      letterSpacing: '-0.75px',
      lineHeight: '130%',
      padding: '0 2.7rem',
      transition: 'background-color, color, border-color',
      transitionDuration: transitionOut,
      transitionTimingFunction,
      '& svg': {
        transition: 'color',
        transitionDuration: transitionOut,
        transitionTimingFunction,
        '&:hover': {
          transition: 'color',
          transitionDuration: transitionIn,
        },
      },
      '&:hover, :focus': {
        background: isBlackOrWhite,
        borderColor: isWhiteOrBlack,
        color: isWhiteOrBlack,
        transitionDuration: transitionIn,
        '& svg': {
          color: isWhiteOrBlack,
        },
      },
      '&:active': {
        background: isWhiteOrBlack,
        borderColor: isBlackOrWhite,
        color: isBlackOrWhite,
        '& svg': {
          color: isBlackOrWhite,
        },
      },
      [theme.breakpoints.up('xl')]: {
        height: '56px',
        lineHeight: '150%',
      },
    };

    const secondaryStyles = {
      ...sharedStyles,
      background: isBlackOrWhite,
      borderColor: isWhiteOrBlack,
      color: isWhiteOrBlack,
      '&:hover, :focus': {
        background: isWhiteOrBlack,
        color: isBlackOrWhite,
      },
      '&:active': {
        background: isBlackOrWhite,
        borderColor: isWhiteOrBlack,
        color: isWhiteOrBlack,
      },
    };

    const variants = {
      primary: {
        ...sharedStyles,
      },
      secondary: {
        ...secondaryStyles,
      },
      tertiary: {
        alignItems: 'center',
        border: 'solid 1px',
        borderColor: theme.palette.transparent,
        borderRadius: '240px',
        color: isWhiteOrBlack,
        display: 'inline-flex',
        fontFamily: theme.typography.sans,
        fontSize: '1.6rem',
        fontWeight: 600,
        height: '40px',
        letterSpacing: '-0.75px',
        paddingRight: '3.6rem',
        transition: 'background-color, color, border-color',
        transitionDuration: '100ms',
        transitionTimingFunction: 'ease-in-out',
        '& a, button': {
          color: isWhiteOrBlack,
          lineHeight: '140%',
          verticalAlign: 'text-bottom',
        },
        '& svg': {
          color: 'black',
        },
        '&:hover, :focus': {
          backgroundColor: isWhiteOrBlack,
          border: 'solid 1px',
          borderColor: theme.palette.transparent,
          color: isBlackOrWhite,
          '& a, button': {
            color: isBlackOrWhite,
          },
          '& svg': {
            color: isBlackOrWhite,
          },
        },
        '&:active': {
          backgroundColor: isBlackOrWhite,
          border: 'solid 1px',
          borderColor: isWhiteOrBlack,
          color: isWhiteOrBlack,
          '& a, button': {
            color: isWhiteOrBlack,
            verticalAlign: 'text-bottom',
          },
          '& svg': {
            color: isWhiteOrBlack,
          },
        },
        '&[type="button"], &[type="submit"]': {
          borderRadius: '240px',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '1.8rem',
          lineHeight: '150%',
        },
      },
      quinary: {
        fontWeight: 600,
        textDecoration: 'none !important',
        textDecorationColor: 'transparent !important',
        color: 'var(--black800) !important',
        transition: 'background-color, color, border-color',
        transitionDuration: transitionOut,
        transitionTimingFunction,
        '& ~ a': {
          textDecoration: 'none !important',
          textDecorationColor: 'transparent !important',
          color: 'var(--black800) !important',
        },
        '& svg': {
          padding: '6px',
          color: 'inherit',
          borderRadius: '50%',
          overflow: 'hidden',
          transition: 'inherit',
        },
        '&:hover, &:focus, p > &:hover, p > &:focus': {
          color: 'var(--black1000) !important',
          textDecoration: 'none  !important',
          textDecorationColor: 'transparent !important',
          '& ~ a': {
            textDecoration: 'none !important',
            textDecorationColor: 'transparent !important',
          },
          '& svg': {
            backgroundColor: 'white',
            color: 'inherit',
          },
        },
      },
    };

    return variants[variant];
  },
);

export default StyledButton;
