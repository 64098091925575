import {
  createInstance,
  enums,
  logging,
  ReactSDKClient,
  setLogger,
} from '@optimizely/react-sdk';
import { eventDispatcher } from 'src/lib/optimizely';

export type OptimizelyDatafile = Record<string, unknown>;

export const fetchDatafile = async (
  url: string,
): Promise<OptimizelyDatafile> => {
  try {
    const datafileResponse = await fetch(url, { mode: 'cors' });

    if (datafileResponse.status !== 200) {
      throw new Error(
        `optimizely datafile fetch failed: ${datafileResponse.status}`,
      );
    }
    return datafileResponse.json();
  } catch (err) {
    console.error(err); // eslint-disable-line
    return null;
  }
};

// Initializes Optimizely using the specified datafile.
// The datafile provides all the configuration needed to run experiments.
// https://docs.developers.optimizely.com/full-stack/docs/get-the-datafile
// https://docs.developers.optimizely.com/full-stack/v2.1/docs/configure-the-logger
export const initialize = (datafile: OptimizelyDatafile): ReactSDKClient => {
  setLogger(logging.createLogger({ logLevel: enums.LOG_LEVEL.ERROR }));

  try {
    const reactSdkClient = createInstance({
      datafile,
      eventDispatcher,
    });

    return reactSdkClient;
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

// Use Activate when the visitor actually sees the experiment.
export const activateUsingOptimizely = (
  optimizelySdkClient: ReactSDKClient,
  experimentKey: string,
): string | null => {
  return optimizelySdkClient.activate(
    experimentKey,
    optimizelySdkClient.user.id,
  );
};

// Use Get Variation when you need to know which bucket a visitor is in
// before showing the visitor the experiment.
// https://docs.developers.optimizely.com/full-stack/v2.1/docs/activate#section-activate-versus-get-variation
export const getVariationUsingOptimizely = (
  optimizelySdkClient: ReactSDKClient,
  experimentKey: string,
): string | null => {
  return optimizelySdkClient.getVariation(experimentKey);
};

/**
 * @description inExperimentGroup will return a boolean if a user is in the control group which is passed along the variant
 * example usage:
 *    inExperimentGroup(
 *      ExperimentName.TEST_EXPERIMENT, // experiment name
 *      TestExperimentVariant.VARIANT,  // name of variant that is set in optimizely
 *      false, // if experiment should also be activated. If this is set to false the variation of the experiment will be returned
 *    ),
 * @param args
 * @param {ReactSDKClient} optimizelySdkClient - Optimizely SDK
 * @param {string} args.experiment - experiment name
 * @param {string} args.variant - name of variant that is set in optimizely
 * @param {boolean=} args.activate - if experiment should be activated
 * @param {string=} args.override - optional string to use an override control group instead
 * @returns {boolean}
 */
export const inExperimentGroup = (
  optimizelySdkClient: ReactSDKClient,
  experiment: string,
  variant: string,
  activate = false,
  override = '',
): boolean => {
  // Allow for override
  if (override) {
    return override === variant;
  }

  // Allow for activation (make a call to optimizely) vs. group retrieval (does local check on optimizely instance)
  if (activate) {
    return activateUsingOptimizely(optimizelySdkClient, experiment) === variant;
  }
  return (
    getVariationUsingOptimizely(optimizelySdkClient, experiment) === variant
  );
};

/**
 * Helper function to ensure experiments are only activated and tracked once for the same experiment on a page
 */
export const activateExperiment = (
  optimizelyClientInstance: ReactSDKClient,
  experimentName: string,
  cache: Record<string, string>,
): string => {
  if (cache[experimentName] === undefined) {
    cache[experimentName] = activateUsingOptimizely(
      optimizelyClientInstance,
      experimentName,
    );
  }
  return cache[experimentName];
};

/**
 * Initializes the optimizelyClientInstance using Segment's ajs_anonymous_id as the optimizely user id
 * We set optimizelyClientInstance on the window so it can be used globally
 */
export const initializeOptimizelyClientInstance = () => {
  const oneTrustCountryCode = window?.OneTrust?.getGeolocationData()?.country;

  // initialize user if not done already
  if (!window.optimizelyClientInstance.user.id) {
    // if anonymous Segment ID is available
    if (window.localStorage.getItem('ajs_anonymous_id')) {
      window.optimizelyClientInstance.setUser({
        id: window.localStorage.getItem('ajs_anonymous_id'),
        attributes: {
          oneTrustCountryCode, // we rely on the country code in Optimizwly to run experiments in specific countries
        },
      });
    } else {
      // if not, have to wait for Segment to initialize
      window.analytics.ready(() => {
        window.optimizelyClientInstance.setUser({
          id: window.localStorage.getItem('ajs_anonymous_id'),
          attributes: {
            oneTrustCountryCode, // we rely on the country code in Optimizwly to run experiments in specific countries
          },
        });
      });
    }
  }
};

/**
 * Helps retrieve necessary datafile to run Optimizely
 * @returns Static datafile consumed by Optimizely experiment logic
 */
export const getOptimizelyDatafileProps = async () => {
  return fetchDatafile(process.env.OPTIMIZELY_DATAFILE_URL).then((datafile) => {
    return {
      props: {
        datafile: datafile,
      },
    };
  });
};
