import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import cx from 'classnames';
import ChevronRightS2 from 'plaid-threads/Icons/ChevronRightS2';
import styled from '@mui/system/styled';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

// helpers
import { prependLocale, prependStagingHash } from 'src/lib/utils';
import { ConditionalWrapper } from 'src/components/helpers';

// contexts
import { usePageLocale } from 'src/contexts';
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';

import styles from './Link.module.scss';

const StyledA = styled('a')({});

const Link = ({
  children,
  trackingId = '',
  sys = { id: '' },
  ...linkProps
}) => {
  const userLocale = usePageLocale();
  const router = useRouter();
  const track = useTrack();
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
  };

  if (router?.locale) {
    // if locale is available then we know next.js' i18n is enabled
    // so we short circuit and just use the Link component as it's intended
    return (
      <NextLink href={linkProps.href} {...linkProps}>
        {children}
      </NextLink>
    );
  }
  // if next i18n locale is not available then fallback to hacking anchors
  // to prepend the hard coded locale to the anchor
  return React.Children.map(children, (child) => {
    if (
      child.type === 'a' ||
      child?.props?.['data-name'] === 'StyledButton' ||
      child?.props?.['data-name'] === 'StyledA'
    ) {
      const { children, className, slots, ...rest } = child.props;
      const localeProp = linkProps.locale || userLocale;
      let href = prependLocale({
        locale: localeProp,
        href: child.props.href || linkProps.href,
      });
      href = prependStagingHash(href, router?.basePath || '');
      const isCtaLink = linkProps?.variant === 'cta';
      return (
        <StyledA
          onClick={(e) => {
            return handleClick(e);
          }}
          // TODO: Replace className with sx props
          className={cx(className || styles.default, {
            [styles.cta]: isCtaLink,
            [styles[`color--${linkProps?.color}`]]: linkProps?.color,
          })}
          {...inspectorProps({
            fieldId: 'text',
          })}
          {...rest}
          href={href}
        >
          <ConditionalWrapper
            condition={isCtaLink}
            wrapper={(children) => {
              return (
                // TODO: Replace className with sx props
                <span className={styles.chevronWrapper}>
                  {children}
                  <ChevronRightS2 className={styles.rightChevron} />
                </span>
              );
            }}
          >
            {children}
          </ConditionalWrapper>
        </StyledA>
      );
    }
    return child;
  });
};

export default styled(Link)({});
