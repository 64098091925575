import React, { FC, ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { TypographyProps } from 'src/components-v2/DataDisplay/Typography/Typography.types';
import MUIGrid from '@mui/system/Unstable_Grid';
import { RichTextSwitch } from 'src/containers';
import { Image } from 'src/components-v2/Media';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import {
  Column,
  Section,
  Container,
  Row,
  Stack,
  Box,
} from 'src/components-v2/Layout';
import { RichTextContent, Sys } from 'contentful';
import { CmsAssetProps } from 'src/components-v2/Media/CmsMediaSwitch/CmsMediaSwitch.types';

export interface FormWrapperProps {
  children: ReactNode;
  backgroundColor?: string;
  textColor?: string;
  body?: RichTextContent | null;
  backgroundAsset?: CmsAssetProps | null;
  styles?: SxProps;
  sys?: Sys;
  id: string;
}

export const FormWrapper: FC<FormWrapperProps> = ({
  children,
  sys = '',
  backgroundColor,
  textColor = 'black',
  body,
  backgroundAsset,
  id,
}) => {
  const isTextColorWhite = textColor === 'white';

  const textColorStyle = {
    color: textColor,
  };

  const contentNodes: { [key: string]: Partial<TypographyProps> } = {
    h1: {
      component: 'h1',
      variant: 'h1',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
      },
    },
    h2: {
      component: 'h2',
      variant: 'h2',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
      },
    },
    h3: {
      component: 'h3',
      variant: 'h3',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
      },
    },
    h6: {
      component: 'h6',
      variant: 'h6',
      className: 'eyebrow-heading',
      fieldId: 'leftColumn',
      sx: {
        color: isTextColorWhite ? 'white' : 'var(--black800)',
      },
    },
    p: {
      component: 'p',
      variant: 'p',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
        fontSize: { lg: '2.4rem' },
      },
    },
  };

  const formNodes: { [key: string]: Partial<TypographyProps> } = {
    h1: {
      component: 'h1',
      variant: 'h1',
      fieldId: 'leftColumn',
    },
    h2: {
      component: 'h2',
      variant: 'h2',
      fieldId: 'leftColumn',
    },
    h3: {
      component: 'h3',
      variant: 'h3',
      fieldId: 'leftColumn',
    },
    h6: {
      component: 'h6',
      variant: 'h6',
      className: 'eyebrow-heading',
      fieldId: 'leftColumn',
      sx: {
        color: 'var(--black800)',
      },
    },
    p: {
      component: 'p',
      variant: 'p',
      fieldId: 'leftColumn',
      sx: {
        fontSize: { lg: '2.4rem' },
      },
    },
  };

  return (
    <Section
      id={id}
      sx={{
        position: 'relative',
        backgroundColor,
      }}
    >
      {backgroundAsset ? (
        <CmsMediaSwitch
          asset={backgroundAsset}
          queryString={formImageQueryString}
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <Image
          src={defaultImage?.desktop}
          alt={defaultImage?.alt}
          width={defaultImage?.width}
          height={defaultImage?.height}
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          sources={[
            {
              media: '(max-width: 412px)',
              srcSet: defaultImage?.mobile + formImageQueryString?.xs,
            },
            {
              media: '(min-width: 413px) and (max-width: 639px)',
              srcSet: defaultImage?.mobile + formImageQueryString?.sm,
            },
            {
              media: '(min-width: 640px) and (max-width: 1023px)',
              srcSet: defaultImage?.tablet + formImageQueryString?.md,
            },
            {
              media: '(min-width: 1024px)',
              srcSet: defaultImage?.desktop + formImageQueryString?.lg,
            },
          ]}
        />
      )}

      <Container
        sx={{
          py: { xs: '16px', sm: '50px', lg: '100px' },
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Row sx={{ m: '0', alignItems: 'flex-start' }}>
          <Column
            xs={22}
            xsOffset={1}
            sm={20}
            smOffset={2}
            lg={8}
            lgOffset={2}
            sx={{
              display: { xs: 'none', lg: 'block' },
              p: '0',
            }}
          >
            <Box
              sx={{
                pt: { xs: '0', lg: '8.9rem' },
                '& .eyebrow-heading, .decorative-text': {
                  mb: { xs: '1.6rem', lg: '2.4rem' },
                },
                '& h1': {
                  mb: { xs: '1.6rem', lg: '2.4rem' },
                  maxWidth: { lg: '563px' },
                },
                '& h2': {
                  mb: { xs: '1.6rem', lg: '2.4rem' },
                },
                '& h3': {
                  mb: { xs: '1.6rem', lg: '2.4rem' },
                },
                '& p': {
                  mb: { xs: '1.6rem', lg: '2.4rem' },
                  maxWidth: { lg: '476px' },
                },
                '& p:nth-last-of-type(1):empty': {
                  display: 'none',
                },
                '& .button-group': {
                  flexDirection: { xs: 'column', sm: 'row' },
                  flexWrap: 'wrap',
                  gap: { xs: '1.8rem', sm: '1.2rem', lg: '2.6rem' },
                },
                // for decorative text
                '& p:has(.decorative-text), h1:has(.decorative-text)': {
                  mb: { xs: 1, md: 2 },
                  // for icons
                  '& svg': {
                    mt: { xs: '0.2rem', md: '0.4rem' },
                    zoom: { xs: 1, md: 1.2 }, // we use zoom because it works for all icons
                  },
                },
              }}
            >
              <RichTextSwitch sys={sys} content={body} nodes={contentNodes} />
            </Box>
          </Column>

          <Column
            xs={22}
            xsOffset={1}
            sm={20}
            smOffset={2}
            lg={10}
            lgOffset={2}
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              p: '0',
            }}
          >
            <MUIGrid
              container
              columns={{ xs: 22, sm: 20, lg: 10 }}
              spacing={{ xs: '0.4rem', sm: '0.8rem', md: '1.6rem' }}
              sx={{
                height: '100%',
              }}
            >
              <Column
                xsOffset={2}
                xs={18}
                smOffset={1}
                sm={18}
                lgOffset={1}
                lg={8}
                sx={{
                  p: '0',
                }}
              >
                <Stack
                  sx={{
                    py: {
                      xs: '3.4rem',
                      sm: '4.0rem',
                      lg: '6.4rem',
                    },
                    '& input': {
                      borderRadius: '8px',
                    },
                    '& i + span': {
                      fontSize: '1.4rem',
                    },
                    '& fieldset': {
                      '& div:has(input)': {
                        borderRadius: '8px',
                      },
                    },
                    '& .eyebrow-heading, .decorative-text': {
                      mb: { xs: '1.6rem', lg: '2.4rem' },
                    },
                    '& h1': {
                      mb: { xs: '1.6rem', lg: '2.4rem' },
                    },
                    '& h2': {
                      mb: { xs: '1.6rem', lg: '2.4rem' },
                    },
                    '& h3': {
                      mb: { xs: '1.6rem', lg: '2.4rem' },
                    },
                    '& p': {
                      mb: { xs: '1.6rem', lg: '2.4rem' },
                    },
                    '& p:nth-last-of-type(1):empty': {
                      display: 'none',
                    },
                    '& .button-group': {
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: { xs: '1.8rem', sm: '1.2rem', lg: '2.6rem' },
                    },
                    // for decorative text
                    '& p:has(.decorative-text), h1:has(.decorative-text)': {
                      mb: { xs: 1, md: 2 },
                      // for icons
                      '& svg': {
                        mt: { xs: '0.2rem', md: '0.4rem' },
                        zoom: { xs: 1, md: 1.2 }, // we use zoom because it works for all icons
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: { lg: 'none' },
                      mb: '1.6rem',
                      maxWidth: { sm: '533px', lg: 'none' },
                    }}
                  >
                    <RichTextSwitch
                      sys={sys}
                      content={body}
                      nodes={formNodes}
                    />
                  </Box>

                  {children}
                </Stack>
              </Column>
            </MUIGrid>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const BASE = '?fm=webp&q=70';
const formImageQueryString = {
  xs: `${BASE}&w=800`,
  sm: `${BASE}&w=1234`,
  md: `${BASE}&w=1902`,
  lg: `${BASE}&w=2430`,
};

// default brushstroke images
const defaultImage = {
  color: 'purple200',
  alt: 'background brushstroke',
  width: 1728,
  height: 811,
  desktop:
    'https://images.ctfassets.net/ss5kfr270og3/7d2e86prvG2wbs6O71ECKI/7850f23b486b3bbb2b1eacb4a275d063/Hero_Desktop_Step1B.jpg',
  tablet:
    'https://images.ctfassets.net/ss5kfr270og3/azpy7VjrPlN7kUIoR9Gn8/ecc7f3e4dc6148143922a626fe93922a/Tablet_Hero_A.jpg',
  mobile:
    'https://images.ctfassets.net/ss5kfr270og3/6mSrfD8rp9KEcnGcNOQYHc/200f5cf213d16ff48dfea41939e0d64c/Mobile_Hero_A.jpg',
};
