import React from 'react';
import { SxProps } from '@mui/system';
import { Stack } from 'src/components-v2/Layout';
import { Link } from 'src/components-v2/Inputs';
import { BackgroundContext, useIsDarkBackground } from 'src/contexts';
import { CmsBackgroundColors } from 'src/lib/ts-types';

interface ICard {
  href: string;
  children: React.ReactNode;
  sx?: SxProps;
  isFeatured?: boolean;
  isDark?: boolean;
  backgroundColor?: CmsBackgroundColors;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Card: React.FC<ICard> = ({
  href,
  children,
  sx,
  isFeatured = false,
  backgroundColor = 'white',
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
}) => {
  const isDark = useIsDarkBackground(backgroundColor);
  const featuredBackgroundColor = isDark ? 'white' : 'black';
  const featuredImage = !isDark
    ? 'https://images.ctfassets.net/ss5kfr270og3/6r9hiLHWUjEUtz9d9d0qrH/8d02279e6498ae2057b00749e54bea47/Black_background.png?fm=webp&w=844&q=10'
    : 'https://images.ctfassets.net/ss5kfr270og3/YClDcTRUcE7M7os6YDFb4/f764e1a5ff47300cb91c7b887797c4cb/Brushstroke-light-featured-grid-card.png?fm=webp&w=844&q=10';

  return (
    <BackgroundContext.Provider
      value={isFeatured ? featuredBackgroundColor : backgroundColor}
    >
      <Link href={href}>
        <a
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          <Stack
            direction='column'
            sx={{
              height: '100%',
              borderRadius: '20px',
              border: 1,
              borderColor: isDark ? 'white' : 'black',
              p: {
                xs: `${
                  isFeatured
                    ? '4.0rem 3.0rem 4.2rem 3.0rem'
                    : '3.4rem 1.3rem 3.2rem 1.4rem'
                }`,
                sm: '4.8rem 3.1rem 5.5rem 4.0rem',
                lg: '4.0rem 4.0rem 2.7rem 4.0rem',
              },
              alignItems: { xs: 'center', sm: 'flex-start' },
              justifyContent: 'flex-start',
              textAlign: { xs: 'center', sm: 'left' },
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: isFeatured
                ? featuredBackgroundColor
                : 'transparent',
              backgroundImage: isFeatured ? `url(${featuredImage})` : 'none',
              '& h3': {
                mb: { xs: '1.5rem', md: '1.6rem' },
              },
              '& p': {
                mb: { xs: '2.0rem', sm: '5.7rem', md: '5.4rem' },
              },
              '& button': {
                marginTop: 'auto',
              },
              '& img': {
                width: { xs: '40px', sm: '64px', md: '80px' },
                mb: { xs: '1.9rem', sm: '3.0rem', md: '1.7rem' },
              },
              '& p:first-child': {
                // TODO: leftover from static fraud-compliance page.  remove once the static page is removed. https://plaidinc.atlassian.net/browse/SITE-5099
                mb: { xs: '4.5rem', md: '2.4rem' },
                mt: { sm: '0.7rem', md: '1.7rem' },
              },
              '& .featured-paragraph': {
                mb: { xs: '1.5rem', sm: '1.6rem', md: '2.4rem' },
              },
              '& p:nth-last-of-type(1):empty': {
                display: 'none',
              },
              ...sx,
            }}
          >
            {children}
          </Stack>
        </a>
      </Link>
    </BackgroundContext.Provider>
  );
};

export default Card;
