import React from 'react';
import PropTypes from 'prop-types';

import { CustomerStoryTile } from 'src/components/CustomerStories';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

const propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  'cta-url': PropTypes.string.isRequired,
  'cta-text': PropTypes.string.isRequired,
};

const RelatedStories = ({
  title,
  items,
  'cta-url': ctaUrl,
  'cta-text': ctaText,
}) => (
  <div className='related-stories' data-testid='related-stories'>
    <div className='grid-container'>
      <h6 className='related-stories__title'>{title}</h6>
      <div className='related-stories__collection'>
        {items.map((item, i) => (
          <article
            key={item.id}
            className={`
            related-stories__item ${i === 2 ? 'hide-for-medium-only' : ''}
          `}
            data-id={item.id}
            data-tags={item.goals && JSON.stringify(item.goals)}
          >
            <CustomerStoryTile {...item} />
          </article>
        ))}
      </div>
      <div className='related-stories__cta'>
        <Link href={ctaUrl}>
          <a>
            <span className='feature-section-link-text'>
              {ctaText} <ArrowRightSingleSmall />
            </span>
          </a>
        </Link>
      </div>
    </div>
  </div>
);

RelatedStories.propTypes = propTypes;
export default RelatedStories;
