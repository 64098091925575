import React, { useEffect, useState } from 'react';
import {
  activateExperiment,
  initializeOptimizelyClientInstance,
} from 'src/lib/optimizely/optimizely';
import { getCookie, setCookie } from 'public/assets/js/analytics/cookie';

import ExperimentOptimizely from './ExperimentOptimizely';

const cache = {};

/**
 * @description ExperimentOptimizelyCached is a wrapper component for the ExperimentOptimizely experiment component for Optimizely Full Stack Integration
 * The purpose of this component is to provide a way to ensure a consistent experience through plaid.com subdomains for users in an experiment.
 * We can achieve this by setting a cookie with the variation received from Optimizely that a user is in and
 * then that can be read from other sites to assert a user is bucketed into the same variation across plaid.com domains
 * @param args
 * @param {Object} args.variantComponents - Components to render based on experiment group user is in
 * @param {ReactComponent=} args.defaultComponent - Default component to render
 * @param {string} args.experimentName - Experiment that will be activated
 */
function ExperimentOptimizelyCached(props) {
  const { variantComponents, defaultComponent = null, experimentName } = props;
  const [activeVariationName, setActiveVariationName] = useState('');
  useEffect(() => {
    if (window.optimizelyClientInstance) {
      initializeOptimizelyClientInstance();
      window.optimizelyClientInstance.onReady().then(() => {
        // we use cookie instead of local storage to make sure
        // the cookie is read across plaid.com subdomains
        const forcedVariantFromCookie = getCookie(
          `${experimentName}_variation`,
        );

        if (
          // forcedVariantFromCookie will be '' if cookie doesnt exist
          forcedVariantFromCookie === ''
        ) {
          const experimentVariation = activateExperiment(
            window.optimizelyClientInstance,
            experimentName,
            cache,
          );
          if (experimentVariation != null) {
            setCookie({
              cname: `${experimentName}_variation`,
              cvalue: experimentVariation,
              exdays: 7,
            });
          }
          setActiveVariationName(experimentVariation);
        } else {
          setActiveVariationName(forcedVariantFromCookie);
        }
      });
    }
  }, [experimentName]);

  return (
    <>
      {/* We always render ExperimentOptimizely with the forcedVariant read from the cookie from this component */}
      <ExperimentOptimizely
        experimentName={experimentName}
        forcedVariant={activeVariationName}
        variantComponents={variantComponents}
        defaultComponent={defaultComponent}
      />
    </>
  );
}

export default ExperimentOptimizelyCached;
