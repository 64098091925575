import React, { useEffect, useState } from 'react';
import { animated, useSpring, easings } from '@react-spring/web';
import {
  activateExperiment,
  initializeOptimizelyClientInstance,
} from 'src/lib/optimizely/optimizely';
import { Box as VBox } from 'src/components-v2/Layout';
import { useAdBlockDetector, useUserLocale } from 'src/hooks';
import { Locales, isProduction } from 'src/lib/constants';
import { normalizeContentSettings } from 'src/containers/ConsentManager/utils';

const Box = animated(VBox);

const cache = {};

/**
 * How do I add an experiment?
 *
 * Well, check out this runbook:
 * https://docs.google.com/document/d/1Sk6kYTlmCNL8YMa2ggKhGv2jTGGVuUeE9Q2maAEljSs
 *
 * but if you'd like a TL;DR:
 * Setup your experiment name in Optimizely with the different variations possible.
 * Add your experiment name and the variation name(s) to src/lib/experiments.ts
 * Add your variations and a default to the component props in case none of the variations match.
 *
 * You can also force a variant with the optional forcedVariant prop.  Note: this does not
 *   send any data to Optimizely as this prop is intended to be used to test flows locally.
 *
 * Add the below to any component which you would like an experiment to run in
 * <AboveTheFoldOptimizelyExperiment
 *   optimizelyEnvironment='development'
 *   loadingComponent={<></>}
 *   defaultComponent={<p>Default Component</p>}
 *   experimentName={ExperimentName.TEST_MEOW}
 *   variantComponents={{
 *     [TestMeowVariant.VARIANT_1]: (<p>Variant 1</p>),
 *     [TestMeowVariant.VARIANT_2]: (<p>Variant 2</p>),
 *   }}
 * />
 *
 * Add getStaticProps to the page in src/pages which will contain your experiment component
 * this is needed so the datafile can be fetched in getStaticProps so we can assert we have it loaded before any
 * components are rendered
 * export const getStaticProps: GetStaticProps = async () =>
 *   fetchDatafile(process.env.OPTIMIZELY_DATAFILE_URL).then((datafile) => ({
 *     props: {
 *       datafile: datafile,
 *     },
 *   }));
 */

/**
 * @description ExperimentOptimizely is the experiment component for Optimizely Full Stack Integration
 * @param args
 * @param {Object} args.variantComponents - Components to render based on experiment group user is in
 * @param {ReactComponent=} args.loadingComponent - Renders to avoid flash of content when experiment is not ready
 * @param {ReactComponent=} args.defaultComponent - Default component to render
 * @param {string=} args.forcedVariant - Experiment group to override user into
 * @param {string} args.experimentName - Experiment that will be activated
 * @param {string} args.optimizelyEnvironment - Set 'production' or 'development' env based on the experiment envrionment enabled in optimizely
 */
const AboveTheFoldOptimizelyExperiment = (props) => {
  const {
    variantComponents,
    defaultComponent = null,
    forcedVariant = null,
    experimentName,
    loadingComponent = null,
    optimizelyEnvironment = 'production',
  } = props;
  const { locale } = useUserLocale();
  const [activeVariationName, setActiveVariationName] = useState('');
  const [isOptimizelyLoaded, setIsOptimizelyLoaded] = useState(false);
  const isEligibleForExperiment = locale.localeCode === Locales.EN_US;
  const [consentSettings, setConsentSettings] = useState<string[] | undefined>(
    undefined,
  );
  const hasAdBlocker = useAdBlockDetector();

  const isEnvrionmentActiveForExperiment =
    optimizelyEnvironment === 'production'
      ? isProduction
      : optimizelyEnvironment === 'development'
      ? !isProduction
      : null;

  const fadeInAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: isOptimizelyLoaded ? 1 : 0,
    },
    config: {
      duration: 100,
      easing: easings.easeInQuad,
    },
  });

  useEffect(() => {
    const waitForOneTrustGroups = setInterval(() => {
      if (window?.OneTrust) {
        setConsentSettings(
          normalizeContentSettings(window?.OnetrustActiveGroups),
        );
        clearInterval(waitForOneTrustGroups);
      }
    }, 100);

    return () => {
      clearInterval(waitForOneTrustGroups);
    };
  }, []);

  useEffect(() => {
    const maybeOptimizely = setInterval(() => {
      if (!!window.optimizelyClientInstance) {
        setIsOptimizelyLoaded(!!window.optimizelyClientInstance);
        clearInterval(maybeOptimizely);
      }
    }, 100);

    if (!isOptimizelyLoaded) {
      return;
    }

    initializeOptimizelyClientInstance();

    window.optimizelyClientInstance.onReady().then(() => {
      const activeVariationName = forcedVariant
        ? forcedVariant
        : activateExperiment(
            window.optimizelyClientInstance,
            experimentName,
            cache,
          );
      setActiveVariationName(activeVariationName);
    });

    return () => {
      return clearInterval(maybeOptimizely);
    };
  }, [experimentName, forcedVariant, isOptimizelyLoaded]);

  if (isEnvrionmentActiveForExperiment) {
    // render loading component for intial html
    if (typeof window === 'undefined') {
      return loadingComponent;
    }

    // users that are not eligible for the experiment
    if (!isEligibleForExperiment) {
      return defaultComponent;
    } else {
      // if the user is eligible for the experiment
      // but we dont have consent info yet, show loading
      if (!consentSettings) {
        return loadingComponent;
      }
      // if the user is eligible for the experiment
      // but has not yet consented, show them a loading component
      else if (
        !consentSettings.some((setting) => {
          return setting === 'C0002';
        })
      ) {
        return defaultComponent;
      } else {
        // if the user is eligible for the experiment, has consented, and buckted
        // show experiment components
        if (activeVariationName && variantComponents[activeVariationName]) {
          return (
            <Box style={fadeInAnimation}>
              {variantComponents[activeVariationName]}
            </Box>
          );
        } else if (hasAdBlocker) {
          return defaultComponent;
        } else {
          // if the user is eligible for the experiment and has consented
          // but not buckted, show loading component
          return loadingComponent;
        }
      }
    }
  } else {
    // show default component when experiment is not running in dev
    return defaultComponent;
  }
};

export default AboveTheFoldOptimizelyExperiment;
