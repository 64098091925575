import React, { useEffect, useState } from 'react';
import {
  activateExperiment,
  initializeOptimizelyClientInstance,
} from 'src/lib/optimizely/optimizely';

const cache = {};

/**
 * How do I add an experiment?
 *
 * Well, check out this runbook:
 * https://docs.google.com/document/d/1Sk6kYTlmCNL8YMa2ggKhGv2jTGGVuUeE9Q2maAEljSs
 *
 * but if you'd like a TL;DR:
 * Setup your experiment name in Optimizely with the different variations possible.
 * Add your experiment name and the variation name(s) to src/lib/experiments.ts
 * Add your variations and a default to the component props in case none of the variations match.
 *
 * You can also force a variant with the optional forcedVariant prop.  Note: this does not
 *   send any data to Optimizely as this prop is intended to be used to test flows locally.
 *
 * Add the below to any component which you would like an experiment to run in
 * <ExperimentOptimizely
 *   defaultComponent={<p>Default Component</p>}
 *   experimentName={ExperimentName.TEST_MEOW}
 *   variantComponents={{
 *     [TestMeowVariant.VARIANT_1]: (<p>Variant 1</p>),
 *     [TestMeowVariant.VARIANT_2]: (<p>Variant 2</p>),
 *   }}
 * />
 *
 * Add getStaticProps to the page in src/pages which will contain your experiment component
 * this is needed so the datafile can be fetched in getStaticProps so we can assert we have it loaded before any
 * components are rendered
 * export const getStaticProps: GetStaticProps = async () =>
 *   fetchDatafile(process.env.OPTIMIZELY_DATAFILE_URL).then((datafile) => ({
 *     props: {
 *       datafile: datafile,
 *     },
 *   }));
 */

/**
 * @description ExperimentOptimizely is the experiment component for Optimizely Full Stack Integration
 * @param args
 * @param {Object} args.variantComponents - Components to render based on experiment group user is in
 * @param {ReactComponent=} args.defaultComponent - Default component to render
 * @param {string=} args.forcedVariant - Experiment group to override user into
 * @param {string} args.experimentName - Experiment that will be activated
 */
function ExperimentOptimizely(props) {
  const {
    variantComponents,
    defaultComponent = null,
    forcedVariant = null,
    experimentName,
  } = props;

  const [activeVariationName, setActiveVariationName] = useState('');
  const [isOptimizelyLoaded, setIsOptimizelyLoaded] = useState(false);

  useEffect(() => {
    const maybeOptimizely = setInterval(() => {
      if (!!window.optimizelyClientInstance) {
        setIsOptimizelyLoaded(!!window.optimizelyClientInstance);
        clearInterval(maybeOptimizely);
      }
    }, 300);

    if (!isOptimizelyLoaded) {
      return;
    }

    initializeOptimizelyClientInstance();

    window.optimizelyClientInstance.onReady().then(() => {
      const activeVariationName = forcedVariant
        ? forcedVariant
        : activateExperiment(
            window.optimizelyClientInstance,
            experimentName,
            cache,
          );
      setActiveVariationName(activeVariationName);
    });

    return () => {
      return clearInterval(maybeOptimizely);
    };
  }, [experimentName, forcedVariant, isOptimizelyLoaded]);

  return (
    <>
      {activeVariationName && variantComponents[activeVariationName]
        ? variantComponents[activeVariationName]
        : defaultComponent}
    </>
  );
}

export default ExperimentOptimizely;
