import React from 'react';
import PropTypes from 'prop-types';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import {
  RichTextShape,
  CtaExperimentShape,
  TargetButtonShape,
} from 'src/lib/prop-types';
import Paywall from 'src/components/Surfaces/Paywall';
import { CtaButton } from 'src/components/Inputs';
import { ExperimentOptimizely } from 'src/components/Optimizely';
import { OPTIMIZELY_EXPERIMENTS_EVENTS } from 'src/lib/optimizely';
import { usePaywallProvider } from 'src/components/Surfaces';

export const CtaShape = PropTypes.shape({
  hasPrimaryCta: PropTypes.bool.isRequired,
  primaryCtaText: PropTypes.string,
  primaryCtaLink: PropTypes.string,
  primaryCtaTrackingId: PropTypes.string,
  hasSecondaryCta: PropTypes.bool.isRequired,
  secondaryCtaText: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
  secondaryCtaTrackingId: PropTypes.string,
  hasTertiaryCta: PropTypes.bool.isRequired,
  tertiaryCta: RichTextShape,
  primaryCtaExperiment: PropTypes.any,
  secondaryCtaExperiment: PropTypes.any,
});

const CtaSectionProps = {
  cta: CtaShape,
  targetButton: TargetButtonShape,
};

const CtaExperiment = ({ ctaExperiment, secondary }) => {
  const variationMap = ctaExperiment?.fields?.meta;

  // create variantComponents object to reuse ExperimentOptimizely
  const variantComponents = Object.entries(variationMap).reduce(
    (acc, [variationKey, variationId]) => {
      const variation = ctaExperiment.fields.variations.find(
        (item) => item.sys.id === variationId,
      );
      // add event to OPTIMIZELY_EXPERIMENTS_EVENTS so it gets tracked
      // this is just getting around that since we have dynamic tracking ids
      if (
        variation.fields.trackingId &&
        !OPTIMIZELY_EXPERIMENTS_EVENTS.includes(variation.fields.trackingId)
      ) {
        OPTIMIZELY_EXPERIMENTS_EVENTS.push(variation.fields.trackingId);
      }

      acc[variationKey] = (
        <CtaButton
          href={variation.fields.url}
          trackingId={variation.fields.trackingId}
          secondary={secondary}
        >
          {variation.fields.text}
        </CtaButton>
      );

      return acc;
    },
    {},
  );

  return (
    <ExperimentOptimizely
      experimentName={ctaExperiment.fields.experimentKey}
      variantComponents={variantComponents}
    />
  );
};
CtaExperiment.propTypes = CtaExperimentShape;

// TODO: https://jira.plaid.com/browse/SITE-2057
// .button-group Foundation class to be decoupled / replaced.
// It is targeted by contextual (leaky scope) selectors in .page-header.scss
// which will also need to be addressed.

const CtaSection = ({ cta, targetButton }) => {
  const {
    setIsModalOpen,
    isContentUnlocked,
    renderOption,
    hasPaywall,
  } = usePaywallProvider();

  return (
    <>
      <div className='button-group button-group--margin-bottom'>
        {cta.hasPrimaryCta && !cta.primaryCtaExperiment && (
          <CtaButton
            href={cta.primaryCtaLink}
            trackingId={cta.primaryCtaTrackingId}
            className='signup-attach-utm'
          >
            {cta.primaryCtaText}
          </CtaButton>
        )}
        {cta.primaryCtaExperiment && (
          <CtaExperiment ctaExperiment={cta.primaryCtaExperiment} />
        )}
        {cta.hasSecondaryCta && !cta.secondaryCtaExperiment && (
          <CtaButton
            href={cta.secondaryCtaLink}
            trackingId={cta.secondaryCtaTrackingId}
            secondary
          >
            {cta.secondaryCtaText}
          </CtaButton>
        )}
        {cta.secondaryCtaExperiment && (
          <CtaExperiment ctaExperiment={cta.secondaryCtaExperiment} secondary />
        )}
      </div>
      {cta.hasTertiaryCta && (
        <RichTextRenderer
          content={cta.tertiaryCta}
          classes={{
            pClassName: 'page-header-subtext',
          }}
        />
      )}
      <Paywall.TargetButton
        targetButton={targetButton}
        setIsModalOpen={setIsModalOpen}
        hasPaywall={hasPaywall}
        isContentUnlocked={isContentUnlocked}
        renderOption={renderOption}
      />
    </>
  );
};

CtaSection.propTypes = CtaSectionProps;

export default CtaSection;
