import React from 'react';

// components
import { Box } from 'src/components-v2/Layout';

interface Props {
  children: React.ReactNode;
}
// visually hides the content but not from the screen readers
const VisuallyHiddenContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      aria-hidden={false}
      sx={{
        position: 'absolute',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        width: '1px',
        p: '0',
        border: '0',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Box>
  );
};

export default VisuallyHiddenContainer;
