import React from 'react';
import { Icon } from 'src/components-v2/DataDisplay';
import { styled } from '@mui/system';
import useMeasure from 'react-use-measure';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const StyledSpan = styled('span')({});

const fontWeightMap = {
  Regular: 400,
  'Semi-Bold': 600,
  Bold: 700,
  'Extra Bold': 800,
};

const getBackgroundImage = (fields, refBounds) => {
  if (fields?.backgroundImage) {
    return {
      '&::before': {
        backgroundImage: `url('${fields?.backgroundImage?.fields?.file?.url.replace(
          /\?(.+)/,
          '?fm=webp&q=1', // we don't set a width since we do not know the size of the uploaded image
        )}')`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        content: '""',
        display: 'block',
        height: refBounds.height,
        position: 'absolute',
        width: refBounds.width,
        zIndex: -1,
      },
    };
  }
};

const DecorativeText = ({ node }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: node?.data?.target?.sys?.id,
  });
  const fields = node?.data?.target?.fields;
  const [ref, refBounds] = useMeasure();
  return (
    <StyledSpan
      className='decorative-text'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '8px',
        ...fields.styles,
      }}
      {...inspectorProps({
        fieldId: 'text',
      })}
    >
      {fields?.icon && (
        <Icon
          icon={fields?.icon?.fields?.icon}
          sx={{
            color: fields.color?.fields?.color || 'inherit',
          }}
        />
      )}
      <StyledSpan
        ref={ref}
        sx={{
          fontFamily: fields.font, // TODO: should pull from the MUI theme.ts?
          fontWeight: fontWeightMap[fields.fontWeight],
          color: fields.color?.fields?.color || 'inherit',
          flex: 1,
          ...getBackgroundImage(fields, refBounds),
        }}
      >
        {fields.text}
      </StyledSpan>
    </StyledSpan>
  );
};
DecorativeText.displayName = 'DecorativeText';

export default DecorativeText;
