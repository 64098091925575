/* eslint-disable import/prefer-default-export */
import { Asset } from 'contentful';
import { ImageShape } from 'src/lib/ts-types';

interface RelatedStoryFields {
  cardImage: Asset;
  excerpt: string;
  metaTitle: string;
  pageSlug: string;
}

interface RelatedStoriesItem {
  fields: RelatedStoryFields;
}

type RelatedStoriesCmsData = RelatedStoriesItem[];

// @TODO: we can extract this out to a common file for reuse
// when / if we convert CustomerStoryTile to TS
interface CustomerStoryTileProps {
  cardImage: ImageShape;
  excerpt: string;
  id: string;
  'link-text'?: string;
  name: string;
  url: string;
}

interface RelatedStoriesProps {
  title: string;
  items: CustomerStoryTileProps[];
  'cta-url': string;
  'cta-text': string;
}

/**
 * Maps Contentful response's Related Stories data to the component's expected props
 */
export const relatedStoriesAdapter = (
  items: RelatedStoriesCmsData,
): RelatedStoriesProps => {
  const storyItems = items.map((item) => ({
    cardImage: {
      alt:
        item?.fields?.cardImage?.fields?.description ||
        item?.fields?.cardImage?.fields?.title ||
        `${item?.fields?.metaTitle} logo`,
      src: item?.fields?.cardImage?.fields?.file?.url,
    },
    excerpt: item?.fields?.excerpt,
    id: item?.fields?.pageSlug,
    name: item?.fields?.metaTitle,
    url: `/customer-stories/${item?.fields?.pageSlug}/`,
  }));

  return {
    title: 'Related customer stories',
    items: storyItems,
    'cta-url': '/customer-stories/',
    'cta-text': 'See all customer stories',
  };
};
