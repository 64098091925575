import React from 'react';
import { SectionProps } from './Section.types';
import styled from '@mui/system/styled';
import { BackgroundContext } from 'src/contexts';

const Section = React.forwardRef<HTMLElement | any, SectionProps>(
  ({ children, component, sx, ...props }, ref) => {
    const Component = component || 'section';
    // @ts-ignore
    const { backgroundColor, bgColor, ...rest } = props;
    return (
      <BackgroundContext.Provider value={backgroundColor || bgColor || 'white'}>
        <Component ref={ref} {...rest}>
          {children}
        </Component>
      </BackgroundContext.Provider>
    );
  },
);

export default styled(Section)({});
