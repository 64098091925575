import styled from '@mui/system/styled';
import { colors, fontFamily, PlaidTheme } from 'src/components-v2/theme';

// *********  Homepage styles **********
const DefaultStyles = {
  fontWeight: '800',
  color: colors.black,
  fontFamily: fontFamily.sans,
  backgroundColor: 'inherit',
  marginBottom: '0.8rem',
  marginTop: '0',
};

// *********  Threads Event styles **********
const DefaultThreadsEventStyles = {
  fontWeight: '600',
  color: colors.black,
  fontFamily: fontFamily.sans,
  backgroundColor: 'inherit',
  marginBottom: '0.8rem',
  marginTop: '0',
};

const inlineLinkStyles = (theme) => {
  // we have to use !important to override the global styles
  return {
    '& a': {
      fontWeight: 600,
      textDecorationColor: `${theme.palette.black600} !important`,
      textDecorationThickness: '1px !important',
      transition: 'text-decoration-color !important',
      transitionDuration: '350ms !important',
      transitionTimingFunction: 'cubic-bezier(0.23, 1.2, 0.32, 1) !important',
      '&:hover, :focus': {
        color: 'inherit !important',
        textDecoration: 'underline !important',
        textDecorationColor: theme.palette.black,
        textDecorationThickness: '2px !important',
        textUnderlineOffset: '4px !important',
      },
      '&:active': {
        textDecorationColor: `${theme.palette.black600} !important`,
        textDecorationThickness: '1px !important',
      },
    },
  };
};

export const themes = {
  // web 2.0 styles
  // bear in mind that these styles are still inheriting from the global styles
  // it's best to inspect the semantic elements and explicitly add the global styles
  // this way when they're removed we will not have any regressions
  // NOTE: that we take a mobile first approach when styling
  default: {
    h1: styled('h1')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '4.0rem',
        fontWeight: 600,
        letterSpacing: '-0.2rem',
        lineHeight: '100%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '8.0rem',
        },
      };
    }),
    h2: styled('h2')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '3.2rem',
        fontWeight: 600,
        letterSpacing: '-0.2rem',
        lineHeight: '110%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '6.0rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '2.4rem',
        fontWeight: 600,
        letterSpacing: '-0.2rem',
        lineHeight: '120%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '4.0rem',
        },
      };
    }),
    h4: styled('h4')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '2.0rem',
        fontWeight: 600,
        letterSpacing: '-0.15rem',
        lineHeight: '130%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '3.0rem',
        },
      };
    }),
    // Subhead Regular
    h5: styled('h5')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '140%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '2.4rem',
        },
        // Subhead SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
      };
    }),
    // P1 Regular
    p: styled('p')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '2.0rem',
        },
        // P1 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P2 Regular
    p2: styled('p')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem',
        },
        // P2 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P3 Regular
    p3: styled('p')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.6rem',
        },
        // P3 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P4 Regular
    p4: styled('p')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem',
        },
        // P4 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P5 Regular
    p5: styled('p')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.sans,
        fontSize: '1.8rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        // P5 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // EYEBROW
    h6: styled('h6')(({ theme }: { theme: PlaidTheme }) => {
      return {
        color: theme.palette.black,
        fontFamily: theme.typography.monospace,
        fontSize: '1.2rem',
        fontWeight: 800,
        letterSpacing: '0.125rem',
        lineHeight: '100%',
        marginBottom: '0',
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
          fontSize: '2.0rem',
          letterSpacing: '0.2rem',
        },
      };
    }),
  },

  // legacy themes to be deprecated
  homepage: {
    h1: styled('h1')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '3.6rem',
        lineHeight: '4.8rem',
        letterSpacing: '-0.028rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '4.8rem',
          lineHeight: '5.6rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '6rem',
          lineHeight: '7.2rem',
        },
      };
    }),
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '3.2rem',
        lineHeight: '4rem',
        letterSpacing: '-0.03rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '3.6rem',
          lineHeight: '4.8rem',
          letterSpacing: '-0.028rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '4.8rem',
          lineHeight: '5.6rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '2.8rem',
        lineHeight: '3.2rem',
        letterSpacing: '-0.028rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '3.2rem',
          lineHeight: '3.8rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '3.6rem',
          lineHeight: '4.6rem',
        },
      };
    }),
    h4: styled('h4')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        letterSpacing: '0.008rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.8rem',
          lineHeight: '2.6rem',
          letterSpacing: '-0.022rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '2.4rem',
          lineHeight: '3.2rem',
        },
      };
    }),
    h5: styled('h5')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '1.4rem',
        lineHeight: '2rem',
        letterSpacing: '0.024rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.8rem',
          lineHeight: '2.6rem',
          letterSpacing: '-0.02rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '2rem',
          lineHeight: '2.8rem',
        },
      };
    }),
    h6: styled('h6')(({ theme }) => {
      return {
        fontSize: '1.5rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.16rem',
        fontWeight: '700',
        color: colors.black700,
        fontFamily: fontFamily.monospace,
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          letterSpacing: '0.15rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '1.4rem',
        lineHeight: '2rem',
        letterSpacing: '0.022rem',
        fontWeight: '400',
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          letterSpacing: '0.008rem',
        },
      };
    }),
    span: styled('span')(({ theme }) => {
      return {
        ...DefaultStyles,
        margin: '0',
      };
    }),
  },
  'threads-event-23': {
    h1: styled('h1')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '7rem',
        lineHeight: '4.4rem',
        letterSpacing: '-0.338rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16rem',
          lineHeight: '9rem',
          letterSpacing: '-0.7rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '18rem',
          lineHeight: '9rem',
          letterSpacing: '-0.7rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '23.3rem',
          lineHeight: '14.5rem',
          letterSpacing: '-1.123rem',
        },
      };
    }),
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '5.25rem',
          lineHeight: '6.5rem',
          letterSpacing: '-0.375rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '6rem',
          lineHeight: '9rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '2.5rem',
        lineHeight: '4rem',
        letterSpacing: '-0.05rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '3.5rem',
          lineHeight: '4.75rem',
          letterSpacing: '-0.2rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    h4: styled('h4')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '2.5rem',
        lineHeight: '4rem',
        letterSpacing: '-0.05rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '2.8rem',
          lineHeight: '4.5rem',
          letterSpacing: '-0.1rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '3.2rem',
          lineHeight: '4.5rem',
          letterSpacing: '-0.15rem',
          fontWeight: '500',
        },
      };
    }),
    h5: styled('h5')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '2.8rem',
        lineHeight: '3.6rem',
        letterSpacing: '-0.15rem',
        fontWeight: '700',
        backgroundColor: 'inherit',
      };
    }),
    h6: styled('h6')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '2.5rem',
        lineHeight: '4.0rem',
        letterSpacing: '-0.05rem',
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
    span: styled('span')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        margin: '0',
      };
    }),
  },
  'post-threads-hero': {
    h1: styled('h1')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '6rem',
        lineHeight: '7.5rem',
        letterSpacing: '-0.425rem',
        [theme.breakpoints.up('lg')]: {
          lineHeight: '9rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
        [theme.breakpoints.up('sm')]: {
          fontSize: '2.5rem',
          lineHeight: '4rem',
          letterSpacing: '-0.05rem',
        },
      };
    }),
  },
  'post-threads-two-column-video-card': {
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '6rem',
          lineHeight: '9rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '3.2rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.15rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    h6: styled('h6')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        letterSpacing: '0.024rem',
        textTransform: 'uppercase',
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
  },
  'post-threads-two-column-video-layout': {
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '6rem',
          lineHeight: '9rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '3.2rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.15rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
  },
  'post-threads-features': {
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '6rem',
          lineHeight: '6.8rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '3.2rem',
        fontWeight: '500',
        lineHeight: '4.5rem',
        letterSpacing: '-0.15rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
  },
  'main-menu': {
    h6: styled('h6')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '1.6rem',
        fontFamily: fontFamily.monospace,
        textTransform: 'uppercase',
        color: colors.black700,
        letterSpacing: '0.15rem',
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '1.6rem',
        fontWeight: '600',
        lineHeight: '2.24rem',
      };
    }),
  },
};
