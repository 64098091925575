import React from 'react';
import { ImageProps } from './Image.types';
import styled from '@mui/system/styled';

// helpers
import { Lazy } from 'src/components-v2/Helpers';

const ImageComponent = styled('img')(() => {
  return {
    display: 'inline-block',
    height: 'auto',
    maxWidth: '100%',
    verticalAlign: 'middle',
  };
});

const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      isLazy = false,
      draggable = false,
      className = '',
      src = '',
      alt = '',
      width = '',
      height = '',
      sources = [],
      ...props
    },
    ref,
  ) => {
    return (
      <picture>
        {sources?.map((item, i) => {
          return (
            <source
              media={item.media}
              srcSet={item.srcSet}
              key={`image-source-${i}`}
            />
          );
        })}
        <Lazy isLazy={isLazy} height={height}>
          <ImageComponent
            className={className}
            src={src}
            alt={alt}
            width={width}
            height={height}
            ref={ref}
            draggable={draggable}
            {...props}
          />
        </Lazy>
      </picture>
    );
  },
);

export default styled(Image)({});
