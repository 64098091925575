import React, { FC, useEffect, useState } from 'react';
import ThreadsTextArea from 'plaid-threads/TextArea';
import cx from 'classnames';
import { nanoid } from 'nanoid';

import styles from './TextArea.module.scss';

import { InputProps } from '../TextInput/TextInput';

const TextArea: FC<InputProps> = ({
  className = styles.textArea,
  isDarkMode,
  pattern,
  register = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  required,
  value = '',
  onChange = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <ThreadsTextArea
      {...restProps}
      {...(restProps.id ? { id: `${restProps.id}-${nanoid()}` } : {})}
      className={cx(className, isDarkMode && styles.textAreaDark)}
      inputRef={register({
        ...(required && { required }),
        ...(pattern && { pattern }),
      })}
      isDarkMode={isDarkMode}
      onChange={(e) => {
        setInputValue(e.target.value);
        onChange(e);
      }}
      value={inputValue}
    />
  );
};

TextArea.displayName = 'TextInput';
export default TextArea;
